<template>
  <JetDialog
    v-model="dialog"
    title="Прикрепить приказ"
    :disabled-apply-button="!notificationId"
    :loading-apply-button="loadingBtn"
    apply-button-text="Прикрепить"
    @on-apply-click="send"
    @on-cancel-click="dialog = false"

  >
    <div class="mb-2">Выберите приказ</div>
    <v-select
      v-model="notificationId"
      :items="tariffs"
      :loading="loadingTariffs"
      label="Приказ"
      color="grey"
      outlined
      item-text="title"
      item-value="id"
    ></v-select>
  </JetDialog>
</template>

<script>
import BaseAction from "@/components/actions/BaseAction";
import JetDialog from "@/components/JetDialog";
import OtherService from "@/services/OtherService";

export default {
  name: "AttachNotification",
  components: {
    JetDialog
  },
  mixins: [
    BaseAction,
  ],
  data: () => ({
    dialog: false,
    loadingTariffs: false,
    tariffs: [],
    notificationId: null,
    loadingBtn: false,
  }),
  mounted() {
    if (!this.selected) {
      return;
    }
    this.dialog = true;
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loadingTariffs = true;
      this.tariffs = await OtherService.getTariffs();
      this.tariffs.forEach(tr => tr.title = tr.shortName + ' ' + tr.uploadDt);
      this.loadingTariffs = false;
    },
    async send() {
      this.loadingBtn = true;
      await OtherService.addNotificationTariffs(this.selected.vctariffingId, this.notificationId);
      this.loadingBtn = false;
      this.collection.refresh();
      this.dialog = false;
    },
  },
}
</script>
